.spinner {
    border: 2px solid #fff;
    border-top: 2px solid #1849A9;
    border-radius: 100%;
    width: 16px;
    height: 16px;
    animation: spin 0.4s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}